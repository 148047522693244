import './contact.scss'

import React, { Component } from "react"

import Layout from "../components/layout"
import SEO from '../components/seo'
import SimpleBar from 'simplebar-react'
import { graphql } from "gatsby"
import logo from "../assets/images/logo.svg"
import logoOriginals200 from "../assets/images/originals200w.jpg"
import logoOriginals400 from "../assets/images/originals400w.jpg"

class ContactTemplate extends Component {
  render() {
    const page = this.props.data.wordpressPage

    return (
      <Layout>
        <SEO title={page.yoast_meta.title} description={page.yoast_meta.desc} />
        <section className="page-left">
          <div className="page-left--wrapper">
            <SimpleBar style={{ maxHeight: '100%' }}>
              <div className="page-left--content page-contact--content">
                <h2>{page.acf.company_name}</h2>
                <h4>FILM PRODUCTION</h4>
                <h4>ADDRESS</h4>
                <p className="p-normal">
                  {page.acf.address_1_line}<br />
                  {page.acf.address_2_line}<br />
                </p>
                <h4>EMAIL</h4>
                <a href={`mailto:${page.acf.main_email}`}>{page.acf.main_email}</a>
                <ul className="page-contact--people">
                  {page.acf.people && page.acf.people.map((person, i) => {
                    return (
                      <li key={i}>
                        <h2>{person.name}</h2>
                        <h4>{person.title}</h4>
                        <a href={`tel:+48${person.phone}`}>+48 {person.phone.match(/.{1,3}/g).join(' ')}</a>
                        <a href={`mailto:${person.email}`}>{person.email}</a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </SimpleBar>
          </div>
        </section>
        <section className="page-right page-contact--right">
          <div className="page-contact--main-logo">
            <img src={logo} alt="Logo Warsaw Production"/>
          </div>
          <h3>Our brands:</h3>
          <div className="page-contact--secondary-logos">
            <a href="https://warsaworiginals.com/" target="_blank" rel="noopener noreferrer" aria-label="Warsaw Originals">
              <img
                srcSet={`${logoOriginals200}, ${logoOriginals400} 2x`}
                src={logoOriginals400}
                alt="Warsaw Originals"
              />
            </a>
          </div>
        </section>
      </Layout>
    )
  }
}

export default ContactTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      slug
      content
      yoast_meta {
        title
        desc
      }
  		acf {
        company_name
        address_1_line
        address_2_line
        nip
        main_email
        people {
          name
          title
          email
          phone
        }
        legal_partner
        legal_logo {
          localFile {
            childImageSharp {
              fixed(width: 200) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        legal_partners_url
        wpt_logo_full {
          localFile {
            childImageSharp {
              fixed(width: 300) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        wpt_pic {
          localFile {
            childImageSharp {
              fixed(width: 300) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`